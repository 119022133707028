import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import { FaWhatsapp } from "react-icons/fa";
import {
  HiOutlineLightBulb,
  HiOutlineAcademicCap,
  HiOutlineSpeakerphone,
  HiOutlineBriefcase,
} from "react-icons/hi";
import Footer from "../components/layout/Footer";
import { FiMail } from "react-icons/fi";
import { Card, CardHeader, CardBody } from "../../src/components/ui/card";
import aryaJha from "../assets/Images/OurTeam/aryaJha.jpg";
import Avatar from "../assets/Images/OurTeam/Avatar.png";
import AvatarFemale from "../assets/Images/OurTeam/AvatarFemale.png";
import SanjanaV from "../assets/Images/OurTeam/sanjanaV.jpg";

const CampusAmbassador = () => {
  const handleApplyEmail = () => {
    window.location.href =
      "mailto:info@unimayt.com?subject=Application%20for%20Campus%20Ambassador%20Program";
  };

  const handleApplyWhatsApp = () => {
    const message =
      "Hi, I'm interested in applying for the Campus Ambassador Program at Unimayt.";
    window.open(
      `https://wa.me/917827521789?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-unimaytLight">
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>

      {/* Main Content */}
      <div className="flex-grow">
        <div className="max-w-6xl mx-auto px-4 py-4">
          {/* Hero Section */}
          <div className="text-center lg:mb-10 mb-6">
            <h1 className="text-subheading lg:text-heading font-heading text-Button">
              Campus Ambassador Program
            </h1>
            <p className="lg:text-paragraph text-subparagraph text-dark max-w-4xl mt-2 md:mt-0 mx-auto">
              Join our dynamic network of student leaders and become the face of
              Unimayt at your college. Shape the future of international
              education while developing valuable leadership skills.
            </p>
          </div>

          {/* Benefits Section */}
          <div className="grid md:grid-cols-2 md:gap-8 gap-4 md:mb-14 mb-4">
            <div className="bg-white rounded-lg shadow-md md:p-6 p-4 hover:shadow-md hover:shadow-ButtonHover">
              <div className="flex items-center md:mb-4 mb-3">
                <HiOutlineLightBulb className="text-Button w-6 h-6 mr-2" />
                <h3 className="lg:text-subheading text-paragraph font-semibold text-Button">
                  Leadership Development
                </h3>
              </div>
              <p className="text-dark lg:text-paragraph text-subparagraph">
                Gain hands-on experience in leadership, event management, and
                community building. Work directly with our team to develop and
                execute campus initiatives.
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-md md:p-6 p-4 hover:shadow-md hover:shadow-ButtonHover">
              <div className="flex items-center md:mb-4 mb-3">
                <HiOutlineAcademicCap className="text-Button w-6 h-6 mr-2" />
                <h3 className="lg:text-subheading text-paragraph font-semibold text-Button">
                  Exclusive Training
                </h3>
              </div>
              <p className="text-dark lg:text-paragraph text-subparagraph">
                Access specialized training sessions on international education,
                digital marketing, public speaking, and professional networking.
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-md md:p-6 p-4 hover:shadow-md hover:shadow-ButtonHover">
              <div className="flex items-center md:mb-4 mb-3">
                <HiOutlineSpeakerphone className="text-Button w-6 h-6 mr-2" />
                <h3 className="lg:text-subheading text-paragraph font-semibold text-Button">
                  Recognition & Rewards
                </h3>
              </div>
              <p className="text-dark lg:text-paragraph text-subparagraph">
                Earn certificates, performance-based incentives, and exclusive
                merchandise. Top performers get priority internship
                opportunities and recommendation letters.
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-md md:p-6 p-4 hover:shadow-md hover:shadow-ButtonHover">
              <div className="flex items-center md:mb-4 mb-3">
                <HiOutlineBriefcase className="text-Button w-6 h-6 mr-2" />
                <h3 className="lg:text-subheading text-paragraph font-semibold text-Button">
                  Career Growth
                </h3>
              </div>
              <p className="text-dark lg:text-paragraph text-subparagraph">
                Build a strong professional network, gain real-world experience,
                and enhance your resume with leadership experience at a global
                education company.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center mb-4 lg:mb-8">
            <div className="flex-grow h-px bg-white mr-1 md:ml-6 ml-2"></div>
            <h2 className="lg:text-smheading text-subheading flex justify-center items-center md:text-smheading font-heading mb-4 md:mb-6 md:mt-0 mt-4 text-Button md:text-left text-center">
              OUR CAMPUS AMBASSADORS
            </h2>
            <div className="flex-grow h-px bg-white ml-1 md:mr-6 mr-2"></div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-4 lg:mb-16 mb-10">
            {[
              {
                name: "Arya Jha",
                university: "ICFAI University",
                location: "Jaipur, India",
                image: aryaJha,
              },
              {
                name: "Unnati Sharma",
                university: "SRM University",
                location: "Amaravati, India",
                image: AvatarFemale,
              },
              {
                name: "Sanjana V",
                university: "SMVCE",
                location: "Raichur, India",
                image: SanjanaV,
              },
              {
                name: "Akhand Pratap",
                university: "Vellore Institute",
                location: "Bhopal, India",
                image: Avatar,
              },
            ].map((ambassador, index) => (
              <Card
                key={index}
                className="w-full bg-white shadow-xl hover:shadow-xl hover:shadow-ButtonHover transition-all duration-700 hover:scale-90"
              >
                <CardHeader className="h-54">
                  <img
                    src={ambassador.image}
                    alt={`${ambassador.name}`}
                    className="h-full w-full"
                  />
                </CardHeader>
                <CardBody>
                  <div className="flex flex-col items-center">
                    <div className="text-paragraph font-semibold text-Button -mt-4 mb-1">
                      {ambassador.name}
                    </div>
                    <div className="text-subparagraph font-semibold text-Button">
                      {ambassador.university}
                    </div>
                    <div className="text-subparagraph font-semibold text-Button">
                      {ambassador.location}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>

          {/* Requirements Section */}
          <div className="bg-white rounded-lg shadow-md p-8 mb-10 hover:shadow-xl hover:shadow-ButtonHover">
            <h2 className="text-2xl font-semibold text-Button mb-6">
              Program Requirements
            </h2>
            <div className="text-dark space-y-3">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-primary rounded-full mr-3"></div>
                <p>Currently enrolled in a college/university program</p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-primary rounded-full mr-3"></div>
                <p>Strong communication and interpersonal skills</p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-primary rounded-full mr-3"></div>
                <p>Active involvement in college activities and communities</p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-primary rounded-full mr-3"></div>
                <p>
                  Passionate about international education and student
                  development
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-primary rounded-full mr-3"></div>
                <p>Minimum commitment of one semester</p>
              </div>
            </div>
          </div>

          {/* Application Section */}
          <div className="text-center max-w-2xl mx-auto md:mb-8 mb-4">
            <h2 className="text-smheading font-semibold text-Button md:mb-4 mb-0">
              Ready to Join?
            </h2>
            <p className="text-dark md:mb-6 mb-6">
              Apply now to become a Campus Ambassador and start your journey
              towards leadership and professional growth with Unimayt.
            </p>
            <div className="flex flex-row gap-6 justify-center">
              <button
                onClick={handleApplyEmail}
                className="bg-Button w-1/2 text-white md:px-8 md:py-3 py-2 rounded-md hover:bg-ButtonHover transition-all duration-700 hover:scale-110 flex items-center justify-center"
              >
                <FiMail className="mr-2" />
                Apply via Email
              </button>
              <button
                onClick={handleApplyWhatsApp}
                className="bg-green-500 w-1/2 text-white lg:px-8 lg:py-3 px-4 py-2 rounded-md hover:bg-green-600 transition-all duration-700 hover:scale-110 flex items-center justify-center"
              >
                <FaWhatsapp className="md:mr-2" />
                Apply via WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="md:m-2">
        <Footer />
      </div>
    </div>
  );
};

export default CampusAmbassador;
