import { Route, Routes } from "react-router-dom";
import Landing from "./components/shared/Landing";
import DataEthics from "./components/layout/FooterComponents/DataEthics";
import TermsAndConditions from "./components/layout/FooterComponents/TermsAndConditions";
import MainResources from "./pages/MainResources";
import OurImpacts from "./components/layout/FooterComponents/OurImpacts";
import PrivacyPolicies from "./components/layout/FooterComponents/PrivacyPolicies";
import About from "./pages/About";
import Login from "./pages/auth/Login";
import Home from "./pages/Home";
import Team from "./components/shared/Team";
import Partnership from "./components/shared/Partnership";
import CostCalculator from "./pages/CostCalculator";
import Footer from "./components/layout/Footer";
import MainJourney from "./pages/MainJourney";
import Friend from "./pages/Friend";
import ContactTeam from "./pages/ContactTeam";
import { clarity } from "react-microsoft-clarity";
import AuthProviders from "./Provider";
import PartnerWidget from "./components/shared/parterWidgets/PartnerWidget";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./components/shared/passwordReset/NewPassword";
import UnimaytCareer from "./pages/UnimaytCareer";
import CampusAmbassador from "./pages/CampusAmbassador";
import StudentServices from "./components/layout/FooterComponents/WhatWeDo/StudentServices";
import AbroadNetwork from "./components/layout/FooterComponents/WhatWeDo/AbroadNetworks";
import Recruitment from "./components/layout/FooterComponents/WhatWeDo/Recruitments";

export default function App() {
  if (process.env.REACT_APP_ENV === "prod") {
    clarity.init("op39qrpyug");
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/Partnership" element={<Partnership />} />
        <Route path="/contact-us" element={<ContactTeam />} />
        <Route path="/partnerWidget" element={<PartnerWidget />} />
        <Route path="/request" element={<ResetPassword />} />
        <Route path="/reset" element={<NewPassword />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/studentServices" element={<StudentServices />} />
        <Route path="/Network" element={<AbroadNetwork />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/campusAmbassador" element={<CampusAmbassador />} />
        <Route path="/careers" element={<UnimaytCareer />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/dataEthics" element={<DataEthics />} />
        <Route path="/ourImpact" element={<OurImpacts />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicies />} />
        <Route path="/contact-us" element={<ContactTeam />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="*" element={<Landing />} />
        <Route path="/partnerWidget" element={<PartnerWidget />} />
        <Route path="/request" element={<ResetPassword />} />
        <Route path="/reset" element={<NewPassword />} />

        <Route
          path="/"
          element={
            <AuthProviders>
              <Home />
            </AuthProviders>
          }
        />
        <Route
          path="/friends/:guid"
          element={
            <AuthProviders>
              <Friend />
            </AuthProviders>
          }
        />
        <Route
          path="/resources"
          element={
            <AuthProviders>
              <MainResources />
            </AuthProviders>
          }
        />
        <Route
          path="/journey"
          element={
            <AuthProviders>
              <MainJourney />
            </AuthProviders>
          }
        />

        <Route
          path="/costCalculator"
          element={
            <AuthProviders>
              <CostCalculator />
            </AuthProviders>
          }
        />
      </Routes>
    </>
  );
}
