import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../ui/sheet";
import { LuAlignLeft } from "react-icons/lu";

const ScrollTiles = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <button onClick={() => setIsOpen(!isOpen)}>
          <LuAlignLeft className="w-5 h-5 text-light" />
        </button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>
            <div className="flex items-center gap-2">
              <LuAlignLeft className="w-5 h-5 text-white md:pt-0 pt-1" />
              <div className="font-inter font-semibold md:text-subheading text-paragraph leading-5 tracking-widest text-light">
                UNIMAYT
              </div>
            </div>
          </SheetTitle>

          <ul className="space-y-4 text-light md:p-4 p-4 md:pt-8 pt-6 text-paragraph text-left">
            <li className="hover:text-dark focus:text-dark cursor-pointer">
              <ScrollLink
                to="login-section"
                smooth={true}
                duration={500}
                offset={-40}
                className="focus:outline-none"
                onClick={handleClose}
              >
                Home
              </ScrollLink>
            </li>

            <li className="hover:text-dark focus:text-dark cursor-pointer">
              <ScrollLink
                to="about-section"
                smooth={true}
                duration={500}
                offset={-40}
                className="focus:outline-none"
                onClick={handleClose}
              >
                About Us
              </ScrollLink>
            </li>
            <li className="hover:text-dark focus:text-dark cursor-pointer">
              <ScrollLink
                to="partnership-section"
                smooth={true}
                duration={500}
                offset={-40}
                className="focus:outline-none"
                onClick={handleClose}
              >
                Partnerships
              </ScrollLink>
            </li>
            <li className="hover:text-dark focus:text-dark cursor-pointer">
              <ScrollLink
                to="team-section"
                smooth={true}
                duration={500}
                offset={-40}
                className="focus:outline-none"
                onClick={handleClose}
              >
                Team
              </ScrollLink>
            </li>
            <li className="hover:text-dark focus:text-dark cursor-pointer">
              <ScrollLink
                to="contact-section"
                smooth={true}
                duration={500}
                offset={-40}
                className="focus:outline-none"
                onClick={handleClose}
              >
                Contact Us
              </ScrollLink>
            </li>
          </ul>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default ScrollTiles;
