import Footer from "../../Footer";
import SecondaryHeader from "../../header/SecondaryHeader";

const StudentServices = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-0 sm:py-8 md:py-0">
        <h1 className="flex items-center justify-center text-smheading md:text-heading font-heading mb-4 md:mb-12 lg:mb-4 mt-2 text-dark">
          Student Services
        </h1>
        <h2 className="text-subheading font-heading mb-2 text-dark md:ml-0">
          Welcome to Unimayt’s Student Services Hub
        </h2>
        <p className="text-subparagraph mb-6 text-dark md:ml-0">
          At Unimayt, we are committed to supporting every aspect of your study
          abroad journey. We understand that moving to a new country can be
          overwhelming, so we have curated services designed to make your
          transition seamless and stress-free.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark md:ml-0">
          Our Key Services
        </h2>

        <h3 className="text-paragraph font-heading mb-1 text-dark md:ml-0">
          1. Accommodation Assistance
        </h3>
        <span className="text-subparagraph text-dark">
          Find the perfect home away from home with our partner, Amber.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Compare options for student housing, from private rooms to shared
          apartments, all tailored to fit your budget and preferences.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          2. Education Loans Simplified
        </h3>
        <span className="text-subparagraph text-dark">
          Access financing solutions through Prodigy Finance.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Learn about flexible loan terms, tailored interest rates, and
          step-by-step guidance on securing your loan.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          3. Peer-to-Peer Student Networking
        </h3>
        <span className="text-subparagraph text-dark">
          Connect with fellow students at your future university before you even
          land.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Join university-specific communities to get insider tips and find
          potential roommates or travel buddies.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          4. Travel and Lifestyle Solutions
        </h3>
        <span className="text-subparagraph text-dark">
          Explore travel packages and student discounts through our affiliates,
          including Trip.com.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Access cost-of-living calculators, local guides, and budgeting tips.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-6 text-dark md:ml-0">
          Why Choose Unimayt?
        </h3>
        <li>
          <strong className="text-dark">Trusted Partnerships: </strong>
          <span className="text-subparagraph text-dark">
            Work with established global brands.
          </span>
        </li>
        <li>
          <strong className="text-dark">Tailored Solutions: </strong>
          <span className="text-subparagraph text-dark">
            Every service is designed with international students in mind.
          </span>
        </li>
        <li>
          <strong className="text-dark">Comprehensive Support: </strong>
          <span className="text-subparagraph text-dark">
            From finances to friendships, we have got you covered.
          </span>
        </li>

        <p className="text-subparagraph mb-8 mt-4 text-dark md:ml-0">
          Discover how Unimayt can help make your study abroad dream a reality!
        </p>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default StudentServices;
