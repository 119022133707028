import Footer from "../../Footer";
import SecondaryHeader from "../../header/SecondaryHeader";

const AbroadNetworks = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-0 sm:py-8 md:py-0">
        <h1 className="flex items-center justify-center text-2xl md:text-heading font-heading mb-4 md:mb-12 lg:mb-12 md:mt-8 mt-4 text-dark">
          Unimayt Abroad Network
        </h1>
        <h2 className="text-lg font-heading mb-2 text-dark md:ml-0">
          Your Community. Your Journey. Your Success.
        </h2>
        <p className="text-subparagraph mb-6 text-dark md:ml-0">
          Studying abroad is not just an academic journey—it’s a life-changing
          experience. The Unimayt Abroad Network is your gateway to a vibrant
          community of students, alumni, and professionals who share your
          aspirations.
        </p>

        <h2 className="text-lg font-heading mb-2 text-dark md:ml-0">
          What Can You Do on the Unimayt Abroad Network?
        </h2>

        <h3 className="text-paragraph font-heading mb-1 text-dark md:ml-0">
          1. Connect with Peers
        </h3>
        <span className="text-subparagraph text-dark">
          Find fellow students heading to your university or city.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Share tips, advice, and experiences to make your transition smoother.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          2. Learn from Alumni
        </h3>
        <span className="text-subparagraph text-dark">
          Gain insights from alumni who’ve walked the path before you.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Ask questions about career opportunities, cultural adaptation, and
          more
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          3. Participate in Community Events
        </h3>
        <span className="text-subparagraph text-dark">
          Join webinars, virtual meetups, and expert panels to enhance your
          knowledge.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Network with industry leaders and explore internship or job
          opportunities.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          4. Access Exclusive Resources
        </h3>
        <span className="text-subparagraph text-dark">
          Get access to guides on visas, travel, housing, and budgeting.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Learn about scholarships, internships, and part-time work
          opportunities.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-6 text-dark md:ml-0">
          Why Join the Unimayt Abroad Network?
        </h3>
        <li>
          <strong className="text-dark">Global Connections: </strong>
          <span className="text-subparagraph text-dark">
            Build friendships and professional networks that last a lifetime.
          </span>
        </li>
        <li>
          <strong className="text-dark">Real-Time Updates: </strong>
          <span className="text-subparagraph text-dark">
            Stay informed about the latest news and opportunities for
            international students.
          </span>
        </li>
        <li>
          <strong className="text-dark">Personalized Support: </strong>
          <span className="text-subparagraph text-dark">
            Tailored resources and advice to help you succeed.
          </span>
        </li>

        <p className="text-subparagraph mb-8 mt-4 text-dark md:ml-0">
          Join the Unimayt Abroad Network today and become part of a supportive,
          thriving global student community!
        </p>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default AbroadNetworks;
