import React from "react";
import OtherProfile from "../components/shared/profile/OtherProfile";
import MainLayout from "../components/layout/MainLayout";
import { useLocalStorage } from "usehooks-ts";
import { Conversation } from "../types/ChatsInterface";

const Friend: React.FC = () => {
  const [conversation, setConversation] = useLocalStorage<Conversation[]>(
    "conversation",
    []
  );


  return (
    <MainLayout conversation={conversation} setConversation={setConversation}>
      <OtherProfile />
    </MainLayout>
  );
};

export default Friend;
