import useInstance from "./instance";
import { ENDPOINTS } from "../config/api";

export default function useAuth() {
  const { instance: api } = useInstance();

  const registerUser = async (obj: {
    first_name: string;
    email: string;
    password: string;
    gender: string;
  }) => {
    const response = await api.post(
      ENDPOINTS.REGISTER,
      new URLSearchParams({
        first_name: obj.first_name,
        email: obj.email,
        password: obj.password,
        gender: obj.gender,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }
    );
    return response.data;
  };

  const loginUser = async (data: { username: string; password: string }) => {
    const response = await api.post(
      ENDPOINTS.LOGIN,
      new URLSearchParams({
        username: data.username,
        password: data.password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }
    );

    return response.data;
  };

  const googleLogin = async (tokenData: { access_token: string }) => {
    const response = await api.post(ENDPOINTS.GOOGLE_LOGIN, tokenData, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  };

  const logoutUser = async () => {
    try {
      const response = await api.get(ENDPOINTS.LOGOUT, { withCredentials: true });
      return { success: true, data: response.data };
    } catch (error: any) {
      // If it's a 422 error, the user might already be logged out
      if (error?.response?.status === 422) {
        // Still return success since the end goal (user being logged out) is achieved
        return { success: true, data: null };
      }
      // For other errors, throw them to be handled by the caller
      throw error;
    }
  };

  const requestResetPassword = async (email: string) => {
    let obj = {
      email: email,
    };
    const response = await api.post(ENDPOINTS.REQUESTRESETPASSWORD, obj);
    return response;
  };

  const resetPassword = async (data: any) => {
    const response = await api.post(ENDPOINTS.RESETPASSWORD, data);
    return response;
  };

  return {
    registerUser,
    loginUser,
    logoutUser,
    googleLogin,
    resetPassword,
    requestResetPassword,
  };
}
