import { Avatar, AvatarFallback, AvatarImage } from "../../ui/Avatar";
import { Message } from "../../../types/ChatsInterface";
import { cn } from "./../../../lib/utils";
import { parseISO, format } from "date-fns";

interface ConversationListInterface {
  last_message: Message;
  user: any;
  new_messages_count: number;
  direct_message_created_at: any;
}

const ChatList = ({
  last_message,
  user,
  new_messages_count,
  direct_message_created_at,
}: ConversationListInterface) => {
  return (
    <>
      <div className="p-4 border-b hover:bg-opacity-55 cursor-pointer">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <Avatar className="h-10 w-10 border-2 border-dark">
                <AvatarImage
                  src={user.user_image || ""}
                  alt={user.first_name}
                />
                <AvatarFallback>
                  <img
                    src={
                      user?.gender === "Female"
                        ? "/icons/female.png"
                        : "/icons/male.png"
                    }
                    alt="Profile"
                    className="w-full h-full rounded-full object-cover"
                  />
                </AvatarFallback>
              </Avatar>
              <span
                className={`absolute bottom-0 right-0 block h-3 w-3 rounded-full ring-2 ring-background ${
                  user.isOnline ? "bg-dark" : "bg-unimaytLight"
                }`}
              />
            </div>
            <div className="flex-grow min-w-0">
              <div className="flex justify-between items-baseline">
                <h3
                  className={cn(
                    "text-paragraph text-base font-medium truncate",
                    new_messages_count > 0 && "font-semibold"
                  )}
                >
                  {user.first_name} {user.last_name}
                </h3>
              </div>
              <p
                className={cn(
                  " text-xs font-medium text-muted-foreground truncate",
                  new_messages_count > 0 && "font-semibold"
                )}
              >
                {(() => {
                  switch (true) {
                    case new_messages_count === 0:
                      return last_message?.content;
                    case new_messages_count === 0 &&
                      last_message.content == null:
                      return "";
                    case new_messages_count > 0:
                      return last_message?.content;
                    default:
                      return null;
                  }
                })()}
              </p>
            </div>
          </div>
          <div className="flex flex-col  items-center justify-around h-10">
            <div className="text-[10px] font-light">
              {last_message !== null
                ? format(parseISO(last_message?.created_at), "HH:mm")
                : `${format(parseISO(direct_message_created_at), "HH:mm")}`}
            </div>

            <div>
              {new_messages_count > 0 && (
                <div className="h-5 w-5 flex items-center text-xs justify-center bg-unimaytLight rounded-full">
                  {new_messages_count}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatList;
