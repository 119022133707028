import { FaLinkedin, FaMailBulk } from "react-icons/fa";
import sidharthBhutaniTeam from "../../assets/Images/OurTeam/sidharthBhutaniTeam.jpg";
import rishabhMahanaTeam from "../../assets/Images/OurTeam/rishabhMahanaTeam.jpg";
import GursahibNegiTeam from "../../assets/Images/OurTeam/GursahibNegiTeam.png";
import SanjanaVeerannaTeam from "../../assets/Images/OurTeam/SanjanaVeerannaTeam.jpeg";
import SecondaryHeader from "../layout/header/SecondaryHeader";
import { useLocation } from "react-router-dom";
import Footer from "../layout/Footer";

const teamMembers = [
  {
    name: "Sidharth Bhutani",
    role: "Founder and CEO",
    description:
      "Driving innovation in student connections and university collaborations worldwide.",
    img: sidharthBhutaniTeam,
    linkedIn: "https://www.linkedin.com/in/sidharthbhutani/",
    email: "sidharth@unimayt.com",
  },
  {
    name: "Rishabh Mahana",
    role: "COO, Product Manager and Co-Founder",
    description:
      "Leading platform development and ensuring seamless student-university engagement.",
    img: rishabhMahanaTeam,
    linkedIn: "https://www.linkedin.com/in/rishabh-mahana/",
    email: "Mahanarishabh@gmail.com",
  },
  {
    name: "Gursahib Nagi",
    role: "UI/UX Designer and Co-Founder",
    description:
      "Crafting intuitive and user-friendly experiences for students and universities.",
    img: GursahibNegiTeam,
    linkedIn: "https://www.linkedin.com/in/gursahib-nagi-64436811a/",
    email: "Gursahib.nagi150@gmail.com",
  },
  {
    name: "Sanjana Veeranna",
    role: "Full Stack Developer",
    description:
      "Building responsive and dynamic interfaces for a seamless user experience.",
    img: SanjanaVeerannaTeam,
    linkedIn: "https://www.linkedin.com/in/sanjana-veeranna/",
    email: "sanjanaveeranna14@gmail.com",
  },
];

const Team = () => {
  const location = useLocation();

  const isAdditional = () => {
    return location.pathname === "/team";
  };

  return (
    <>
      {isAdditional() && (
        <div className="fixed top-0 left-0 w-full z-50">
          <SecondaryHeader search dropdown />
        </div>
      )}
      <div className="bg-unimaytLight lg:h-screen flex justify-center items-center pt-14 lg:pt-40 lg:pb-32">
        <section>
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:pb-0 pt-4 lg:px-0">
            <div className="flex items-center justify-center mb-8 lg:mb-8 md:mt-14">
              <div className="flex-grow h-px bg-white mr-1 md:ml-6 ml-2"></div>
              <h2 className="text-smheading lg:text-heading font-heading text-dark whitespace-nowrap">
                Meet Our Team
              </h2>
              <div className="flex-grow h-px bg-white ml-1 md:mr-6 mr-2"></div>
            </div>
            <div className="mx-auto mb-8 max-w-screen-sm">
              <p className="text-light text-paragraph">
                We're committed to bridging the gap between students and
                universities. Get to know us more.
              </p>
            </div>
            <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {teamMembers.map((member, index) => (
                <div
                  key={index}
                  className="text-center text-light group transition-colors duration-300 p-1 rounded-lg transform hover:scale-105"
                >
                  <img
                    className="mx-auto mb-4 w-36 h-36 rounded-full transition-transform duration-500 transform group-hover:scale-110 border-2"
                    src={member.img}
                    alt={`${member.name} Avatar`}
                  />
                  <h3 className="mb-1 text-subheading font-heading tracking-tight transition-transform duration-500 transform group-hover:scale-105">
                    <a href="#">{member.name}</a>
                  </h3>
                  <p className="text-paragraph">{member.role}</p>
                  <p className="text-subparagraph text-light transition-transform duration-500 transform group-hover:scale-105">
                    {member.description}
                  </p>
                  <ul className="flex justify-center mt-4 space-x-4">
                    <li>
                      <a
                        href={member.linkedIn}
                        aria-label={`LinkedIn profile of ${member.name}`}
                        className="text-light hover:text-dark transition-colors duration-300"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${member.email}`}
                        aria-label={`Email ${member.name}`}
                        className="text-light hover:text-dark transition-colors duration-300"
                      >
                        <FaMailBulk />
                      </a>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {isAdditional() && (
        <div className="md:p-2 bg-unimaytLight">
          <Footer />
        </div>
      )}
    </>
  );
};

export default Team;
