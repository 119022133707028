import { Avatar, AvatarFallback, AvatarImage } from "../../ui/Avatar";
import { Button } from "../../ui/Button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getAuthState from "../../../hooks/getAuthState";
import { cn } from "../../../lib/utils";
import { Message } from "../../../types/ChatsInterface";
import { useUserStore } from "../../../zustandStore/useUserStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IoSend } from "react-icons/io5";
import { ScrollArea } from "../../ui/ScrollArea";
import { IoMdArrowRoundBack } from "react-icons/io";
import { format } from "date-fns";
// import Spinner from "../../ui/spinner";
import { useNavigate } from "react-router-dom";

interface ChatRoomInterface {
  messages: Message[];
  newMessage: any;
  setMessages: any;
}

const ChatRoom = ({ messages, newMessage, setMessages }: ChatRoomInterface) => {
  const [inputMsg, setInputMsg] = useState("");
  const [localChats, setLocalChats] = useState<any[]>([]);
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(true);

  const selectedUser = useUserStore((state) => state.selectedUser);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, localChats, scrollToBottom]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //     scrollToBottom();
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, [scrollToBottom]);

  const typeOfConversation = () => {
    if (selectedUser?.users) {
      const recipient = selectedUser.users.find(
        (user: any) => user.guid !== getAuthState().guid
      );
      return recipient;
    } else {
      return selectedUser;
    }
  };

  const queryClient = useQueryClient();

  const handleSendMessage = useCallback(() => {
    if (inputMsg.trim() === "") return;
    const optimisticMsge = [
      {
        type: "new_message",
        user_guid: getAuthState().guid,
        chat_guid: selectedUser?.chat_guid,
        content: inputMsg,
      },
    ];
    setLocalChats((prev) => [...prev, ...optimisticMsge]);
    newMessage(inputMsg);
    setInputMsg("");
    setTimeout(scrollToBottom, 0);
  }, [inputMsg, newMessage, selectedUser?.chat_guid, scrollToBottom]);

  const memoizedMessages = useMemo(() => {
    const uniqueMessageGuids = new Set();
    setLocalChats([]);
    return messages.filter((msg: any) => {
      if (uniqueMessageGuids.has(msg.message_guid)) {
        return false;
      }
      uniqueMessageGuids.add(msg.message_guid);
      return true;
    });
  }, [messages]);

  const handleViewProfileClick = () => {
    navigate(`/friends/${typeOfConversation()?.guid}`, {
      state: { chat_guid: selectedUser?.chat_guid },
    });
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <div className="h-16 border-b border-unimaytLight flex items-center justify-between bg-unimaytLight md:rounded-t-2xl">
        <div className="flex items-center gap-x-0 my-4">
          <Button
            onClick={() => {
              setMessages([]);
              queryClient.invalidateQueries({ queryKey: ["chats"] });
              setSelectedUser(null);
            }}
            variant="ghost"
            size="icon"
            className="h-9 w-9 font-heading hover:bg-unimaytLight"
          >
            <IoMdArrowRoundBack className="h-5 w-5 text-light" />
          </Button>

          <Avatar
            className="h-8 w-8 border-2 border-white rounded-full hover:cursor-pointer"
            onClick={handleViewProfileClick}
          >
            {typeOfConversation()?.user_image ? (
              <AvatarImage
                src={typeOfConversation().user_image}
                alt={typeOfConversation()?.first_name || "Profile"}
                className="w-full h-full rounded-full object-cover"
              />
            ) : (
              <AvatarFallback className="border h-full w-full font-heading text-lg border-light rounded-full">
                <img
                  src={
                    typeOfConversation()?.gender === "Female"
                      ? "/icons/female.png"
                      : "/icons/male.png"
                  }
                  alt="Profile"
                  className="w-full h-full rounded-full object-cover"
                />
              </AvatarFallback>
            )}
          </Avatar>

          <div>
            <h3
              className="text-paragraph font-semibold ml-2 text-light hover:cursor-pointer hover:text-ButtonHover"
              onClick={handleViewProfileClick}
            >
              {typeOfConversation()?.first_name}
            </h3>
          </div>
        </div>
      </div>

      {/* {messages.length === 0 ? (
        <div className="flex items-center justify-center">
          <img
            src={"/icons/ripples.svg"}
            alt={"loading"}
            height={1}
            width={1}
            className="w-20 h-20 object-contain"
          />
        </div>
      ) : ( */}
      <ScrollArea className="flex-grow bg-light">
        <div className="bg-light flex justify-center items-center mt-6">
          <div className="flex flex-col items-center justify-center gap-y-2">
            <Avatar className="h-28 w-28 border-2 border-dark rounded-full flex justify-center items-center">
              {typeOfConversation()?.user_image ? (
                <AvatarImage
                  src={typeOfConversation().user_image}
                  alt={typeOfConversation()?.first_name || "Profile"}
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <AvatarFallback className="border h-full w-full font-heading text-lg border-light rounded-full">
                  <img
                    src={
                      typeOfConversation()?.gender === "Female"
                        ? "/icons/female.png"
                        : "/icons/male.png"
                    }
                    alt="Profile"
                    className="w-full h-full rounded-full object-cover"
                  />
                </AvatarFallback>
              )}
            </Avatar>
            <div>
              <h3 className="text-subheading font-semibold text-dark">
                {typeOfConversation()?.first_name}
              </h3>
            </div>
            <div
              key={typeOfConversation()?.guid}
              className="btn -mt-1 text-subparagraph w-24 h-8 cursor-pointer flex items-center justify-center rounded-md text-white"
              onClick={handleViewProfileClick}
            >
              View Profile
            </div>
          </div>
        </div>

        {/* Loading Effect */}
        {/* {isLoading ? (
          <div className="flex justify-center items-center h-40 mt-16 text-dark">
            <Spinner />
          </div>
        ) : ( */}
        <div className="flex flex-1 flex-col gap-2 rounded-md px-2 pt-2">
          <div className="flex size-full flex-1">
            <div className="relative -mr-4 flex flex-1 flex-col-reverse overflow-y-hidden">
              <div className="flex h-full w-full flex-col justify-end gap-y-1 pb-2 pr-4">
                {[...memoizedMessages, ...localChats].map(
                  (message: any, index: number) =>
                    message &&
                    message.content &&
                    message.content.trim() !== "" && (
                      <div
                        key={`${message.sender}-${message.timestamp}-${index}`}
                        className={cn(
                          "max-w-72 relative  break-words px-3 py-2 shadow-lg flex flex-col",
                          message.user_guid === getAuthState().guid
                            ? "self-end rounded-[16px_16px_0_16px] bg-primary/85 text-primary-foreground/75"
                            : "self-start rounded-[16px_16px_16px_0] mb-1 bg-secondary"
                        )}
                      >
                        <div className={cn("mr-5")}>{message.content}</div>
                        <div
                          className={cn(
                            "text-[9px]  absolute bottom-1.5 right-1 font-extralight text-gray-400 mt-0.5 self-end"
                          )}
                        >
                          {message.created_at
                            ? format(message.created_at, "HH:mm")
                            : format(new Date().getTime(), "HH:mm")}
                        </div>
                      </div>
                    )
                )}
                <div ref={scrollRef} />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </ScrollArea>
      {/* )} */}
      <div className="bg-Gray rounded-2xl border border-Gray my-2 mx-1 lg:m-1">
        <div className="flex items-center justify-center">
          <input
            type="text"
            placeholder="Type a message..."
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            className="flex-grow ml-1 bg-Gray outline-none"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <div className="bg-dark rounded-lg flex items-center justify-center m-1 w-14 h-9 p-4">
            <button
              onClick={() => {
                handleSendMessage();
              }}
              className="text-light"
            >
              <IoSend className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRoom;
