import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import Australia from "../../assets/Logos/FlagLogos/Australia.svg";
import Canada from "../../assets/Logos/FlagLogos/Canada.svg";
import European_Union from "../../assets/Logos/FlagLogos/European_Union.svg";
import India from "../../assets/Logos/FlagLogos/India.svg";
import United_Kingdom from "../../assets/Logos/FlagLogos/United_Kingdom.svg";
import United_States from "../../assets/Logos/FlagLogos/United_States.svg";
import { IoLogoFacebook } from "react-icons/io";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleConnectClick = () => {
    if (location.pathname === "/login") {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      navigate("/contact-us");
      handleScrollToTop();
    }
  };

  const handleAboutClick = () => {
    if (location.pathname === "/login") {
      const contactSection = document.getElementById("about-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      navigate("/about");
      handleScrollToTop();
    }
  };

  return (
    <footer className="bg-dark text-light py-8 flex justify-center items-center md:rounded-2xl md:mr-2">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
          <div className="mb-6 lg:mb-0">
            <h4 className="font-heading text-subheading">UNIMAYT</h4>
            <p className="text-subparagraph">© 2024 Unimayt.</p>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-heading text-subheading mb-4">What We Do</h4>
            <ul className="text-subparagraph space-y-2 hover:cursor-pointer">
              <li>
                <a href="/studentServices" className="hover:underline">
                  Student Services
                </a>
              </li>
              <li>
                <a href="Network" className="hover:underline">
                  Unimayt Abroad Network
                </a>
              </li>
              <li>
                <a href="recruitment" className="hover:underline">
                  Student Recruitment <br />
                  (For Universities)
                </a>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-heading text-subheading mb-4">Join Us</h4>
            <ul className="text-subparagraph space-y-2 hover:cursor-pointer">
              <li className="hover:underline">
                <a href="/campusAmbassador">Campus Ambassador</a>
              </li>
              <li className="hover:underline">
                <div onClick={() => {
                  navigate("/careers");
                  handleScrollToTop();
                }}>
                  Unimayt Career
                </div>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-heading text-subheading mb-4">Site Map</h4>
            <ul className="text-subparagraph space-y-2 hover:cursor-pointer">
              <li>
                <a onClick={handleAboutClick} className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <div
                  className="hover:underline"
                  onClick={() => {
                    navigate("/ourImpact");
                    handleScrollToTop();
                  }}
                >
                  Our Impact
                </div>
              </li>
              <li>
                <div
                  className="hover:underline"
                  onClick={() => {
                    navigate("/dataEthics");
                    handleScrollToTop();
                  }}
                >
                  Data Ethics
                </div>
              </li>
              <li>
                <div
                  className="hover:underline"
                  onClick={() => {
                    navigate("/privacyPolicy");
                    handleScrollToTop();
                  }}
                >
                  Privacy Policy
                </div>
              </li>
              <li>
                <div
                  className="hover:underline"
                  onClick={() => {
                    navigate("/TermsAndConditions");
                    handleScrollToTop();
                  }}
                >
                  Terms and Conditions
                </div>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-heading text-subheading mb-4">Know Us More</h4>
            <ul className="text-subparagraph space-y-2">
              <li>
                <a
                  href="mailto:info@unimayt.com"
                  className="hover:font-semibold"
                >
                  info@unimayt.com
                </a>
              </li>
              <ul>
                <div className="flex flex-wrap gap-x-1">
                  <div className="flex items-center">
                    <img
                      src={Australia}
                      alt="Australia"
                      className="w-8 h-8 mr-1"
                    />
                  </div>
                  <div className="flex items-center">
                    <img src={Canada} alt="Canada" className="w-8 h-8 mr-1" />
                  </div>
                  <div className="flex items-center">
                    <img
                      src={European_Union}
                      alt="European Union"
                      className="w-8 h-5 mr-1"
                    />
                  </div>
                </div>
              </ul>
              <ul>
                <div className="flex flex-wrap gap-x-1">
                  <div className="flex items-center">
                    <img src={India} alt="India" className="w-8 h-5 mr-1" />
                  </div>
                  <div className="flex items-center">
                    <img
                      src={United_Kingdom}
                      alt="United Kingdom"
                      className="w-8 h-8 mr-1"
                    />
                  </div>
                  <div className="flex items-center">
                    <img
                      src={United_States}
                      alt="United States"
                      className="w-8 h-8 mr-1"
                    />
                  </div>
                </div>
              </ul>

              <li className="pt-4">
                <button
                  onClick={handleConnectClick}
                  className="bg-unimaytLight hover:border-1 hover:bg-ButtonHover text-light font-semibold p-1 rounded"
                >
                  Connect to Our Team
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-heading text-subheading mb-4">Let's Connect</h4>
            <ul className="text-subparagraph space-x-4 flex flex-row">
              <li>
                <a
                  href="https://www.linkedin.com/company/unimayt/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin
                    size={25}
                    className="transition-all duration-300 hover:scale-125"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/unimayt_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare
                    size={25}
                    className="transition-all duration-300 hover:scale-125"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61567517819057&mibextid=LQQJ4d"
                  className="transition-all duration-300 hover:scale-125"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoFacebook
                    size={25}
                    className="transition-all duration-300 hover:scale-125"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;