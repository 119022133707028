import { getTokenExpirationTime } from "./getTokenExpirationTime";

export const isTokenAboutToExpire = () => {
  const currentTime = new Date().getTime();
  const expirationTime = getTokenExpirationTime();
  if (expirationTime !== null) {
    return expirationTime - currentTime <= 60 * 1000;
  } else {
    return false;
  }
};
