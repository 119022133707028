import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { useLocalStorage } from "usehooks-ts";
import { Conversation } from "../types/ChatsInterface";
import Journey from "../components/shared/Journey";

const MainJourney: React.FC = () => {
  const [conversation, setConversation] = useLocalStorage<Conversation[]>(
    "conversation",
    []
  );

  return (
    <MainLayout conversation={conversation} setConversation={setConversation}>
      <Journey />
    </MainLayout>
  );
};

export default MainJourney;
