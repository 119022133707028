import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import { FaWhatsapp } from "react-icons/fa";
import Footer from "../components/layout/Footer";
import { FiMail } from "react-icons/fi";

const UnimaytCareer = () => {
  const handleApplyEmail = () => {
    window.location.href =
      "mailto:info@unimayt.com?subject=Application%20for%20Graphic%20Designer%20Intern%20Position";
  };

  const handleApplyWhatsApp = () => {
    const message =
      "Hi, I'm interested in applying for the Graphic Designer Intern position at Unimayt.";
    window.open(
      `https://wa.me/917827521789?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-unimaytLight">
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>
      <div className="flex-grow max-w-6xl mx-auto px-4 py-4">
        <div className="text-center lg:mb-10 md:mb-6 mb-4">
          <h1 className="text-smheading lg:text-heading font-heading text-Button">
            Unimayt Careers
          </h1>
          <p className="lg:text-paragraph text-subparagraph text-dark max-w-4xl mt-0 md:mt-0 mx-auto">
            We are Looking for a Passionate people to join us on our mission. We
            value flat hierarchies, clear communications and responsibility.
          </p>
        </div>

        {/* Job Listing Card */}
        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-lg shadow-md md:p-6 p-4 hover:shadow-md hover:shadow-ButtonHover">
            <h2 className="lg:text-subheading text-subheading font-bold text-Button mb-4">
              Graphic Designer Intern
            </h2>
            <div className="text-dark lg:text-paragraph text-subparagraph space-y-3 mb-6 mt-2">
              <p>
                We are seeking a creative and enthusiastic Graphic Designer
                Intern to join our dynamic team. This role offers an excellent
                opportunity to gain hands-on experience in a fast-paced creative
                environment.
              </p>
              <p>
                The ideal candidate will assist in creating visual content for
                various digital platforms, collaborate with our marketing team,
                and contribute fresh ideas to our design projects.
              </p>
            </div>
            <div className="flex md:flex-row flex-col md:gap-6 gap-4">
              <button
                onClick={handleApplyEmail}
                className="bg-Button md:w-1/2 w-full text-white md:px-8 md:py-3 py-2 rounded-md hover:bg-ButtonHover transition-all duration-700 hover:scale-110 flex items-center justify-center"
              >
                <FiMail className="mr-2" />
                Apply via Email
              </button>
              <button
                onClick={handleApplyWhatsApp}
                className="bg-green-500 md:w-1/2 w-full text-white lg:px-8 lg:py-3 px-4 py-2 rounded-md hover:bg-green-600 transition-all duration-700 hover:scale-110 flex items-center justify-center"
              >
                <FaWhatsapp className="mr-2" />
                Apply via WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:m-2">
        <Footer />
      </div>
    </div>
  );
};

export default UnimaytCareer;
