export const protectedRoutes = [
  "/user/profile",
  "/users",
  "/user/",
  "/chats/direct",
  "/chat/direct",
  "/recently_chatted_users",
  "/upload-acceptance-letter",
  "/upload",
  "/user/update",
  "/upload-profile-picture",
  "/chat/",
  "/users/",
];
