export const getRefreshTokenExpirationTime = () => {
  const exp = localStorage.getItem("ref_exp");
  return exp ? new Date(exp).getTime() : null;
};
export const isRefreshTokenExpire = () => {
  const currentTime = new Date().getTime();
  const expirationTime = getRefreshTokenExpirationTime();
  if (expirationTime !== null) {
    return currentTime > expirationTime;
  }
  return true;
};
