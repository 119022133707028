import { useCallback, useEffect, useRef, useState } from "react";
import getAuthState from "../../../hooks/getAuthState";
import { Message } from "../../../types/ChatsInterface";
import { getWebSocketURL } from "../../../hooks/getWebSocketUrl";
import UseChat from "../../../services/useChat";
import { SearchIcon, XIcon } from "lucide-react";
import { Input } from "../../ui/Input";
import ConversationList from "./ConversationList";
import ChatRoom from "./ChatRoom";
import { useUserStore } from "../../../zustandStore/useUserStore";
import { ScrollArea } from "../../ui/ScrollArea";
import { useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import SpinBladeLoader from "../../loaders/SpinBladeLoader";

interface MessagingWindowProps {
  setConversation?: any;
  conversation?: any;
  chat_guid?: string;
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean | undefined;
  status?: any;
}

const MessagingWindow: React.FC<MessagingWindowProps> = ({
  setConversation,
  conversation,
  chat_guid,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  status,
}: any) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const { getMessages } = UseChat();

  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const chatRoomOpen = useUserStore((state) => state.chatRoomOpen);

  const chatGuid = useUserStore((state) => state.chatGuid);

  const selectedUser = useUserStore((state) => state.selectedUser);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const queryClient = useQueryClient();

  const sendMessage = useCallback(
    (content: string) => {
      if (
        !chatRoomOpen ||
        !content.trim() ||
        !socketRef.current ||
        !getAuthState()
      )
        return;
      const messageData = {
        type: "new_message",
        user_guid: getAuthState().guid,
        chat_guid: chatRoomOpen.chat_guid ? chatRoomOpen.chat_guid : chatGuid,
        content: content.trim(),
      };
      socketRef.current?.send(JSON.stringify(messageData));
    },
    [chatGuid, chatRoomOpen]
  );

  const readMessage = useCallback((chat_guid: string, message_guid: string) => {
    if (!chat_guid || !socketRef.current || !message_guid || !getAuthState())
      return;
    if (socketRef?.current?.readyState === WebSocket.OPEN) {
      const messageData = {
        type: "message_read",
        chat_guid: chat_guid,
        message_guid: message_guid,
      };
      socketRef.current?.send(JSON.stringify(messageData));
    }
  }, []);

  const fetchMessages = useCallback(async (chatGuid: string) => {
    if (!chatGuid) {
      return;
    }
    try {
      const response = await getMessages(chatGuid);

      if (response?.data?.messages) {
        const unreadMessages = response.data.messages.filter(
          (message: Message) =>
            message.user_guid !== getAuthState().user_guid && !message.is_read
        );

        unreadMessages.forEach((message: Message) =>
          readMessage(chatGuid, message.message_guid)
        );

        setMessages(response.data.messages);
      }
    } catch (error) {}
  }, []);

  const socketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (getAuthState()) {
      let reconnectAttempts = 0;

      const connectWebSocket = () => {
        const ws = new WebSocket(getWebSocketURL());
        socketRef.current = ws;

        ws.onopen = () => {
          reconnectAttempts = 0;
        };

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);

          if (data) {
            if (chatRoomOpen?.chat_guid) {
              setMessages((prevMessages) => [...prevMessages, data]);
            }
            if ("message_guid" in data) {
              queryClient.invalidateQueries({ queryKey: ["chats"] });
            }
          }
        };

        ws.onclose = () => {
          if (socketRef.current) {
            socketRef.current = null;
          }
          reconnectAttempts++;
          const reconnectDelay = Math.min(500 * reconnectAttempts, 5000);
          setTimeout(connectWebSocket, reconnectDelay);
        };

        ws.onerror = (error) => {
          ws.close();
        };
      };

      connectWebSocket();

      return () => {
        if (socketRef.current) {
          socketRef.current.onclose = null;
          socketRef.current.close();
          socketRef.current = null;
        }
      };
    }
  }, [setConversation, chatRoomOpen?.chat_guid, queryClient]);

  const OpenSelectedChatRoom = useCallback(
    async (user: any) => {
      if (!user) return;
      if (user && user.chat_guid) {
        fetchMessages(user.chat_guid);
        setChatRoomOpen(user);
      }
      if (user && user.chat_guid === undefined && chatGuid) {
        fetchMessages(chatGuid);
        setChatRoomOpen(user);
      }
    },
    [fetchMessages]
  );

  const [hasOpenedChat, setHasOpenedChat] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (selectedUser !== null && !hasOpenedChat) {
        OpenSelectedChatRoom(selectedUser);
        setHasOpenedChat(true);
      }
    }
  }, [selectedUser, OpenSelectedChatRoom, hasOpenedChat]);

  useEffect(() => {
    if (selectedUser !== null) {
      setHasOpenedChat(false);
    }
  }, [selectedUser]);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredChats = conversation?.filter((chat: any) =>
    chat.users.some((user: any) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  const { ref: loaderRef, inView } = useInView({
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <div className="h-full w-full border border-gray-400 md:rounded-2xl flex flex-col">
      {selectedUser === null ? (
        <>
          <div className="h-16 border-b border-gray-300 font-medium md:text-smheading text-subheading flex items-center justify-center bg-dark text-light p-4 md:rounded-t-2xl relative">
            <button
              className="absolute top-1/2 right-3 transform -translate-y-1/2 text-white text-lg lg:hidden"
              onClick={() => setChatRoomOpen(null)}
            >
              <XIcon className="font-heading hover:bg-unimaytLight" />
            </button>
            Messaging
          </div>

          <div className="p-4 border-b border-gray-300">
            <div className="relative">
              <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
              <Input
                type="text"
                placeholder="Search messages"
                className=" bg-Gray"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <ScrollArea
            className={`flex-grow ${selectedUser && isMobile() ? "hidden" : ""}`}
          >
            {filteredChats && filteredChats.length > 0 ? (
              filteredChats.map((chat: any) => (
                <div
                  key={chat.chat_guid}
                  onClick={() => setSelectedUser(chat)}
                  className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                >
                  {chat?.users
                    ?.filter((user: any) => user.guid !== getAuthState().guid)
                    .map((user: any) => (
                      <ConversationList
                        key={user.guid}
                        last_message={chat.last_message}
                        direct_message_created_at={chat.created_at}
                        user={user}
                        new_messages_count={chat.new_messages_count}
                      />
                    ))}
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No chats found. Start a new conversation!
              </div>
            )}

            <div>
              {status === "success" && (
                <div ref={loaderRef} className="h-1 w-full"></div>
              )}
              {isFetchingNextPage ? (
                <div className="flex justify-center items-center">
                  <SpinBladeLoader />
                </div>
              ) : (
                !isFetching &&
                hasNextPage === false && (
                  <div className="my-4 text-center text-base text-primary">
                    You are all caught up 😌
                  </div>
                )
              )}
            </div>
          </ScrollArea>
        </>
      ) : (
        <ChatRoom
          newMessage={sendMessage}
          messages={messages}
          setMessages={setMessages}
        />
      )}
    </div>
  );
};

export default MessagingWindow;
