import Footer from "../../Footer";
import SecondaryHeader from "../../header/SecondaryHeader";

const Recruitments = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-0 sm:py-8 md:py-0">
        <h1 className="flex items-center justify-center text-smheading md:text-heading font-heading mt-2 text-dark">
          Student Recruitment
        </h1>
        <div className="flex justify-center items-center mb-8 md:mb-12 lg:mb-12 text-subheading md:text-smheading font-heading text-dark -mt-2">
          (For Universities)
        </div>

        <h2 className="text-subheading font-heading mb-2 text-dark md:ml-0">
          Empowering Universities to Recruit Globally
        </h2>
        <p className="text-subparagraph mb-6 text-dark md:ml-0">
          Unimayt bridges the gap between universities and prospective students
          with a cutting-edge recruitment platform. We ensure universities can
          focus on building diverse, talented student bodies while we take care
          of the connection process.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark md:ml-0">
          Why Partner with Unimayt?
        </h2>

        <h3 className="text-paragraph font-heading mb-1 text-dark md:ml-0">
          1. Targeted Student Matching
        </h3>
        <span className="text-subparagraph text-dark">
          Use our AI-driven matchmaking technology to connect with students who
          align with your program criteria.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Filter candidates based on academic achievements, career aspirations,
          and geographical preferences.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          2. Enhanced Engagement Tools
        </h3>
        <span className="text-subparagraph text-dark">
          Host virtual events, Q&A sessions, and webinars to interact directly
          with prospective students.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Showcase your campus and programs through dynamic profiles and video
          content.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          3. Data-Driven Insights
        </h3>
        <span className="text-subparagraph text-dark">
          Access analytics on student interests and behaviors to refine your
          recruitment strategy.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Leverage detailed reports to improve application conversion rates.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-4 text-dark md:ml-0">
          4. Global Reach
        </h3>
        <span className="text-subparagraph text-dark">
          Tap into a global network of students actively seeking higher
          education opportunities.
        </span>
        <br />
        <span className="text-subparagraph text-dark">
          Increase enrollments while maintaining the quality of applicants.
        </span>

        <h3 className="text-paragraph font-heading mb-1 mt-6 text-dark md:ml-0">
          How It Works
        </h3>
        <li>
          <strong className="text-dark">Step 1: </strong>
          <span className="text-subparagraph text-dark">
            Universities onboard onto Unimayt’s recruitment platform.
          </span>
        </li>
        <li>
          <strong className="text-dark">Step 2: </strong>
          <span className="text-subparagraph text-dark">
            We connect universities with pre-qualified, interested students.
          </span>
        </li>
        <li>
          <strong className="text-dark">Step 3: </strong>
          <span className="text-subparagraph text-dark">
            Universities and students engage directly, streamlining the
            application and enrollment process.
          </span>
        </li>

        <p className="text-subparagraph mb-8 mt-4 text-dark md:ml-0">
          Join the future of student recruitment with Unimayt and expand your
          global presence effortlessly.
        </p>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default Recruitments;
