import { useState } from "react";
import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import Footer from "../components/layout/Footer";
import { IoLogoFacebook } from "react-icons/io";

const ContactTeam = () => {
  const location = useLocation();

  const isAdditional = () => {
    if (location.pathname === "/login") {
      return false;
    } else if (location.pathname === "/contact-us") {
      return true;
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendMessageOnWhatsApp = () => {
    const { firstName, lastName, email, phone, message } = formData;
    const fullName = `${firstName} ${lastName}`;
    const whatsappMessage = `*Name:* ${fullName}\n*Email:* ${email}\n*Phone:* ${phone}\n*Message:* ${message}`;

    const whatsappLink = `https://wa.me/917827521789?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="flex flex-col min-h-screen bg-unimaytLight">
      <div className="sticky top-0 z-10 mb-12 md:mb-0">
        {isAdditional() && (
          <div className="fixed top-0 left-0 w-full z-50">
            <SecondaryHeader search dropdown />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center mb-4 md:mb-0 md:mt-20 mt-8">
        <div className="flex-grow h-px bg-white mr-1 md:ml-24 lg:ml-72 ml-2"></div>
        <h2 className="px-4 text-smheading lg:text-heading font-heading whitespace-nowrap text-dark">
          Contact Here
        </h2>
        <div className="flex-grow h-px bg-light ml-1 md:mr-24 lg:mr-72 mr-2"></div>
      </div>

      <div className="flex-grow flex justify-center items-center w-full bg-unimaytLight">
        <div className="container my-4 px-4 lg:px-10">
          <div className="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl bg-light">
            <div className="flex">
              <h1 className="font-heading text-subheading text-dark">
                Send Us a Message
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
              <div className="input-container">
                <input
                  name="firstName"
                  className="input-field"
                  type="text"
                  placeholder="First Name*"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-container">
                <input
                  name="lastName"
                  className="input-field"
                  type="text"
                  placeholder="Last Name*"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-container">
                <input
                  name="email"
                  className="input-field"
                  type="email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-container">
                <input
                  name="phone"
                  className="input-field"
                  type="number"
                  placeholder="Phone*"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="my-4 mt-6">
              <div className="input-container">
                <textarea
                  name="message"
                  placeholder="Message*"
                  className="input-field"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="my-2 w-1/2 lg:w-1/4">
              <button onClick={sendMessageOnWhatsApp} className="btn">
                Send Message
              </button>
            </div>
          </div>

          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-dark rounded-2xl mb-16">
            <div className="flex flex-col text-light">
              <h1 className="font-heading uppercase text-subheading mb-4">
                Connect with Our Experts...
              </h1>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <h2 className="text-paragraph">UNIMAYT</h2>
                  <p className="text-subparagraph text-gray-400">
                    Since 2024....
                  </p>
                  <p className="text-subparagraph text-gray-400">
                    Connect to the peers globally
                  </p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <h2 className="text-paragraph">Call Us</h2>
                  <ul className="text-gray-400">
                    Mail:
                    <a href="mailto:info@unimayt.com"> info@unimayt.com</a>
                  </ul>
                  <p className="text-gray-400">Tel: +91 782-752-1789</p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2 ml-2 gap-x-4">
                <a
                  href="https://www.linkedin.com/company/unimayt/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-all duration-300 hover:scale-125"
                >
                  <FaLinkedin size={25} />
                </a>
                <a
                  href="https://www.instagram.com/unimayt_/"
                  className="transition-all duration-300 hover:scale-125"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare size={25} />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61567517819057&mibextid=LQQJ4d"
                  className="transition-all duration-300 hover:scale-125"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoFacebook size={25} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAdditional() && (
        <div className="md:p-2 bg-unimaytLight">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default ContactTeam;
