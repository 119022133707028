import useAuth from "../../../services/useAuth";
import { useMutation } from "@tanstack/react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import EditProfileModal from "../../shared/profile/EditProfileModel";
import { useUserStore } from "../../../zustandStore/useUserStore";
import ScrollTiles from "./components/scrollTiles";
import NavigateTiles from "./components/navigateTiles";
import NoLoginTiles from "./components/noLoginTiles";
import getAuthState from "./../../../hooks/getAuthState";

interface SecondaryHeaderProps {
  editProfile?: boolean;
  search?: boolean;
  title?: string;
  dropdown?: boolean;
  setConversation?: any;
}

const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  editProfile = false,
  search = false,
  dropdown = false,
}) => {
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const [isEditProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const location = useLocation();
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isAuthenticated = getAuthState();

  const showEditProfile = ["/", "/home", "/friends"].includes(
    location.pathname
  );

  const logoutMutation = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("exp");
      navigate("/login");
    },
  });

  const renderSidebarMenu = () => {
    if (location.pathname === "/login") {
      return <ScrollTiles />;
    }
    return isAuthenticated ? <NavigateTiles /> : <NoLoginTiles />;
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowSettings(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="min-w-full bg-dark">
      <header className="flex items-center p-3 justify-between overflow-x-hidden">
        <div className="flex flex-row items-center gap-2">
          {renderSidebarMenu()}
          <div
            onClick={() => {
              navigate("/home");
            }}
            className="font-inter cursor-pointer font-semibold text-paragraph md:text-subheading leading-5 tracking-widest text-extralight"
          >
            UNIMAYT
          </div>
        </div>
        <div className="flex items-center space-x-2 md:space-x-2 md:mr-2 mr-1">
          {search && (
            <>
              <div className="hidden sm:block relative rounded-lg">
                <input
                  type="text"
                  placeholder="Search"
                  className="p-2 pl-4 border-light border-1 placeholder:text-placeholder w-[42dvh] lg:w-[48dvh] bg-extralight rounded-xl"
                />
                <div className="absolute right-12 top-2 h-6 w-[2px] bg-black" />
                <IoSearch
                  className="absolute right-4 top-3 text-black"
                  size={20}
                />
              </div>
              <IoSearch
                className="block sm:hidden text-extralight cursor-pointer settings-button transition-all duration-300 hover:scale-125"
                size={30}
              />
            </>
          )}
          {dropdown && (
            <IoMdSettings
              className="text-extralight cursor-pointer settings-button transition-all duration-300 hover:scale-125"
              size={34}
              onClick={() => {
                setShowSettings((prevState: boolean) => !prevState);
              }}
            />
          )}
          {showSettings && (
            <div
              className="absolute top-16 md:right-2 right-1 rounded-2xl bg-extralight shadow-md pr-4 pl-4 md:mt-1 w-42 settings-popup"
              style={{ zIndex: 1 }}
            >
              <div className="absolute -top-2 right-5 w-4 h-4 bg-extralight rotate-45"></div>
              <div className="flex flex-col font-semibold text-dark md:text-paragraph text-subparagraph">
                {isAuthenticated &&
                location.pathname.startsWith("/friends/") ? (
                  <>
                    <button
                      className="py-1 px-1 hover:text-ButtonHover"
                      onClick={() => {
                        navigate("/home");
                        setShowSettings(false);
                      }}
                    >
                      My Profile
                    </button>
                    <hr className="border-dark" />
                  </>
                ) : (
                  showEditProfile &&
                  editProfile && (
                    <>
                      <button
                        className="py-1 px-1 hover:text-ButtonHover"
                        onClick={() => {
                          setEditProfileModalOpen(
                            (prevState: boolean) => !prevState
                          );
                          setShowSettings((prevState: boolean) => !prevState);
                        }}
                      >
                        Edit Profile
                      </button>
                      <hr className="border-dark" />
                    </>
                  )
                )}

                {isAuthenticated ? (
                  <>
                    <button
                      onClick={() => {
                        setSelectedUser(null);
                        logoutMutation.mutateAsync();
                        window.scrollTo({ top: 0 });
                      }}
                      className="py-1 hover:text-ButtonHover"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <Link to="/login">
                    <button className="py-1 px-6 hover:text-ButtonHover">
                      Login
                    </button>
                  </Link>
                )}

                <hr className="border-dark" />
                <Link to="/contact-us">
                  <button className="py-1 px-8 hover:text-ButtonHover">
                    Help
                  </button>
                </Link>
              </div>
              <div className="absolute top-2 right-2">
                <span className="block w-3 h-3 bg-extralight rotate-45"></span>
              </div>
            </div>
          )}
        </div>
      </header>
      {isEditProfileModalOpen && (
        <EditProfileModal
          isEditing={isEditProfileModalOpen}
          setIsEditing={setEditProfileModalOpen}
        />
      )}
    </div>
  );
};

export default SecondaryHeader;
