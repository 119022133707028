export const SkeletonLoader = () => (
  <div className="w-full h-screen">
    <div className="animate-pulse flex flex-col space-y-6 h-full md:w-[70dvw] w-screen">
      <div className="relative w-full h-48 bg-gray-400/70 bg-cover bg-center">
        <div className="absolute -bottom-12 left-2 md:left-4 md:-bottom-28">
          <div className="md:w-40 md:h-40 w-28 h-28 bg-gray-400/70 rounded-full border-4 border-white"></div>
        </div>
      </div>

      <div className="flex items-center space-x-4 md:mx-48 mx-4 gap-x-2 pb-8">
        <div className="flex-1 space-y-4">
          <div className="h-4 bg-gray-400/70 rounded md:w-40 w-28 mx-28 md:mx-0"></div>
          <div className="space-y-2">
            <div className="h-3 bg-gray-400/70 rounded w-64"></div>
            <div className="h-3 bg-gray-400/70 rounded w-64"></div>
            <div className="h-3 bg-gray-400/70 rounded w-64"></div>
          </div>
        </div>
      </div>

      <div className="mb-4 bg-gray-400/70 p-2 rounded relative h-64"></div>
    </div>
  </div>
);
