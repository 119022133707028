import React from "react";
import { Navigate } from "react-router-dom";
import getAuthState from "./hooks/getAuthState";

const AuthProviders = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = getAuthState();

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

export default AuthProviders;
