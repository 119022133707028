import React, { useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowDown,
  MdClose,
} from "react-icons/md";
import magoosh from "../../assets/Logos/PartnerLogos/magoosh.png";
import coursera from "../../assets/Logos/PartnerLogos/coursera.png";
import udemy from "../../assets/Logos/PartnerLogos/udemy.jpg";
import duolingo from "../../assets/Logos/PartnerLogos/duolingo.png";
import chopras from "../../assets/Logos/PartnerLogos/chopras.avif";
import idp from "../../assets/Logos/PartnerLogos/idp.png";
import shiksha from "../../assets/Logos/PartnerLogos/shiksha.jpg";
import timesEducation from "../../assets/Logos/PartnerLogos/timesEducation.png";
import qsWorldRanking from "../../assets/Logos/PartnerLogos/qsWorldRanking.png";
import educationUSA from "../../assets/Logos/PartnerLogos/educationUSA.jpg";
import britishCouncil from "../../assets/Logos/PartnerLogos/britishCouncil.png";
import daad from "../../assets/Logos/PartnerLogos/daad.png";
import icici from "../../assets/Logos/PartnerLogos/icici.jpg";
import kotak from "../../assets/Logos/PartnerLogos/kotak.jpg";
import makeMyTrip from "../../assets/Logos/PartnerLogos/makeMyTrip.jpg";
import booking from "../../assets/Logos/PartnerLogos/booking.png";
import scholarships from "../../assets/Logos/PartnerLogos/scholarships.jpg";
import fastweb from "../../assets/Logos/PartnerLogos/fastweb.svg";
import kayak from "../../assets/Logos/PartnerLogos/kayak.png";
import scyscanner from "../../assets/Logos/PartnerLogos/scyscanner.jpg";
import ivisa from "../../assets/Logos/PartnerLogos/ivisa.png";
import visahq from "../../assets/Logos/PartnerLogos/visahq.jpg";
import amber from "../../assets/Logos/PartnerLogos/amber.png";
import housinganywhere from "../../assets/Logos/PartnerLogos/housinganywhere.png";
import roombuddy from "../../assets/Logos/PartnerLogos/roombuddy.png";
import universityliving from "../../assets/Logos/PartnerLogos/universityliving.jpg";
import uniplaces from "../../assets/Logos/PartnerLogos/uniplaces.png";
import airbnb from "../../assets/Logos/PartnerLogos/airbnb.png";
import target from "../../assets/Logos/PartnerLogos/target.jpg";
import walmart from "../../assets/Logos/PartnerLogos/walmart.png";
import citymarket from "../../assets/Logos/PartnerLogos/citymarket.jpg";
import apple from "../../assets/Logos/PartnerLogos/apple.png";
import amazon from "../../assets/Logos/PartnerLogos/amazon.png";
import isic from "../../assets/Logos/PartnerLogos/isic.png";
import unidays from "../../assets/Logos/PartnerLogos/unidays.png";
import ikea from "../../assets/Logos/PartnerLogos/ikea.png";
import { cn } from "../../lib/utils";

const MiniResources: React.FC = () => {
  const [activeTopic, setActiveTopic] = useState<string | null>(null);
  const [activeSubtopic, setActiveSubtopic] = useState<string | null>(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState<string | null>(null);
  const [expandedTopic, setExpandedTopic] = useState<string | null>(null);

  const mainTopics = [
    {
      title: "Admissions",
      subtopics: ["Courses", "Counselling", "Rankings"],
    },
    {
      title: "Finances",
      subtopics: ["Scholarships", "Loans"],
    },
    {
      title: "Travel",
      subtopics: ["Air Tickets", "Visa", "Insurance", "Forex"],
    },
    {
      title: "Accommodations",
      subtopics: ["Student Housing", "Temporary Stay"],
    },
    {
      title: "Shopping",
    },
  ];

  const handleTopicClick = (title: string) => {
    if (title === "Shopping") {
      setSelectedSubTopic(title);
      setExpandedTopic(null);
    } else {
      setExpandedTopic(expandedTopic === title ? null : title);
      setActiveTopic(title);
      setActiveSubtopic(null);
      setSelectedSubTopic(null);
    }
  };

  const handleSubtopicClick = (topic: string, subtopic: string) => {
    setActiveTopic(topic);
    setActiveSubtopic(subtopic);
    setSelectedSubTopic(subtopic);
  };

  const closePopup = () => {
    setSelectedSubTopic(null);
  };

  const getResourcesForSubtopic = (subtopic: string) => {
    switch (subtopic) {
      case "Courses":
        return [
          { name: "Magoosh", url: "https://magoosh.com/", img: magoosh },
          { name: "Coursera", url: "https://www.coursera.org/", img: coursera },
          { name: "Udemy", url: "https://www.udemy.com/", img: udemy },
          { name: "Duolingo", url: "https://www.duolingo.com/", img: duolingo },
        ];
      case "Counselling":
        return [
          { name: "The Chopras", url: "https://tcglobal.com/", img: chopras },
          { name: "IDP", url: "https://www.idp.com/", img: idp },
          {
            name: "Shiksha.com",
            url: "https://www.shiksha.com/",
            img: shiksha,
          },
        ];
      case "Rankings":
        return [
          {
            name: "Times Education",
            url: "https://www.timeshighereducation.com/",
            img: timesEducation,
          },
          {
            name: "QS World Ranking",
            url: "https://www.topuniversities.com/",
            img: qsWorldRanking,
          },
          {
            name: "EducationUSA",
            url: "https://educationusa.state.gov/",
            img: educationUSA,
          },
          {
            name: "British Council",
            url: "https://www.britishcouncil.org/",
            img: britishCouncil,
          },
          { name: "DAAD", url: "https://www.daad.de/en/", img: daad },
        ];

      case "Scholarships":
        return [
          {
            name: "Scholarships",
            url: "http://www.scholarship.com/",
            img: scholarships,
          },
          { name: "Fastweb", url: "https://www.fastweb.com/", img: fastweb },
        ];
      case "Loans":
        return [
          { name: "ICICI", url: "https://www.icicibank.com/", img: icici },
          {
            name: "Kotak",
            url: "https://www.kotak.com/en/home.html",
            img: kotak,
          },
        ];

      case "Air Tickets":
        return [
          {
            name: "Make My Trip",
            url: "https://www.makemytrip.com/",
            img: makeMyTrip,
          },
          { name: "Booking", url: "https://www.booking.com/", img: booking },
          {
            name: "Kayak",
            url: "https://www.kayak.co.in/",
            img: kayak,
          },
          {
            name: "Scy Scanner",
            url: "https://www.skyscanner.net/media/media-assets",
            img: scyscanner,
          },
        ];
      case "Visa":
        return [
          {
            name: "Ivisa",
            url: "https://ivisatravel.com/",
            img: ivisa,
          },
          {
            name: "Visahq",
            url: "https://www.visahq.in/",
            img: visahq,
          },
          {
            name: "Travel Agency",
            url: "https://www.skyscanner.net/media/media-assets",
            img: scyscanner,
          },
        ];
      case "Insurance":

      case "Forex":
        return [
          { name: "ICICI", url: "https://www.icicibank.com/", img: icici },
          {
            name: "Kotak",
            url: "https://www.kotak.com/en/home.html",
            img: kotak,
          },
        ];

      case "Student Housing":
        return [
          {
            name: "Amber",
            url: "https://amberstudent.com/",
            img: amber,
          },
          {
            name: "Housing Anywhere",
            url: "https://housinganywhere.com/",
            img: housinganywhere,
          },
          {
            name: "University Living",
            url: "https://www.universityliving.com/",
            img: universityliving,
          },
          {
            name: "Room Buddy",
            url: "https://roombuddy.com.au/",
            img: roombuddy,
          },
          {
            name: "Uniplaces",
            url: "https://www.uniplaces.com/",
            img: uniplaces,
          },
        ];
      case "Temporary Stay":
        return [
          {
            name: "Airbnb",
            url: "https://www.airbnb.co.in/",
            img: airbnb,
          },
        ];

      case "Shopping":
        return [
          {
            name: "Target",
            url: "https://corporate.target.com/",
            img: target,
          },
          {
            name: "Walmart",
            url: "https://www.walmart.com/",
            img: walmart,
          },
          {
            name: "City Market",
            url: "https://www.citymarket.com/",
            img: citymarket,
          },
          {
            name: "Apple",
            url: "https://www.apple.com/in/",
            img: apple,
          },
          {
            name: "Ikea",
            url: "https://www.ikea.com/",
            img: ikea,
          },
          {
            name: "Unidays",
            url: "https://www.myunidays.com/",
            img: unidays,
          },
          {
            name: "Amazon",
            url: "https://www.amazon.in/student-discount/",
            img: amazon,
          },
          {
            name: "ISIC",
            url: "https://www.isic.org/",
            img: isic,
          },
        ];
      default:
        return [];
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="w-full p-2 flex-grow">
        <div className="text-smheading lg:text-heading font-heading mb-4 text-dark text-right pr-4 lg:pr-14 md:pr-0">
          Resources
        </div>
        <div className="flex flex-col">
          <div className="w-full text-right lg:pr-10">
            <div className="text-subheading lg:text-smheading lg:pt-2">
              {mainTopics.map((topic) => (
                <div key={topic.title} className="-my-2 lg:my-2">
                  <div
                    className={cn(
                      "cursor-pointer py-2 px-4 rounded-md transition-all ease-in-out duration-300 flex items-center justify-end",
                      activeTopic === topic.title ||
                        expandedTopic === topic.title ||
                        (selectedSubTopic === topic.title &&
                          topic.title === "Shopping")
                        ? "text-dark font-heading hover:text-bold"
                        : "text-dark font-semibold",
                      activeTopic !== topic.title &&
                        expandedTopic !== topic.title &&
                        selectedSubTopic !== topic.title
                        ? "opacity-50"
                        : ""
                    )}
                    onMouseEnter={() => setActiveTopic(topic.title)}
                    onMouseLeave={() => setActiveTopic(null)}
                    onClick={() => handleTopicClick(topic.title)}
                  >
                    <span className="ml-2">
                      {topic.title === "Shopping" &&
                      selectedSubTopic === "Shopping" ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : expandedTopic === topic.title ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowLeft />
                      )}
                    </span>
                    {topic.title}
                  </div>

                  {expandedTopic === topic.title &&
                    topic.subtopics &&
                    topic.subtopics.length > 0 && (
                      <ul className="lg:pl-16 text-paragraph lg:text-subheading font-heading text-left text-dark flex flex-col gap-2 justify-end">
                        {topic.subtopics.map((subtopic, index) => (
                          <li
                            key={subtopic}
                            className={`cursor-pointer transition-all ease-in-out duration-300 flex items-center justify-start ${
                              activeSubtopic === subtopic
                                ? "text-dark font-heading"
                                : "opacity-70 text-dark font-subheading"
                            } hover:opacity-100 hover:text-dark`}
                            onClick={() => {
                              handleSubtopicClick(topic.title, subtopic);
                              if (index === topic.subtopics.length - 1) {
                                setSelectedSubTopic(subtopic);
                              }
                            }}
                          >
                            {subtopic}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[10vh] md:h-[10vh] lg:h-[10vh]"></div>{" "}
      {selectedSubTopic && (
        <div className="relative bottom-8 left-4 right-2 md:left-2 md:right-2 border-2 border-light lg:w-[72dvw] w-11/12 px-2 lg:pl-10 py-2 lg:py-6 bg-gradient-to-r from-GradientFrom to-GradientTo rounded-lg shadow-[0px_0px_15px_black] z-50">
          <div className="relative w-full">
            <button
              className="absolute right-2 top-2 lg:top-0 lg:right-4 text-subheading font-heading text-light"
              onClick={closePopup}
            >
              <MdClose />
            </button>
            <h2 className="text-smheading font-heading mb-4 lg:mb-4 text-light">
              <span className="text-heading font-heading">
                {selectedSubTopic[0]}
              </span>
              {selectedSubTopic.slice(1)}
            </h2>

            <div className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-8 gap-2 mt-6 lg:mt-2 mr-0">
              {getResourcesForSubtopic(selectedSubTopic).map((resource) => (
                <a
                  href={resource.url}
                  key={resource.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center transition-all duration-300 hover:scale-125"
                >
                  <img
                    src={resource.img}
                    alt={resource.name}
                    className="w-12 h-12 mb-2 border border-white transform transition-transform duration-300 ease-in-out group-hover:scale-110 rounded-lg shadow-inner hover:border-2 hover:border-white"
                  />
                  <span className="text-subparagraph font-subheading text-light transform transition-transform duration-300 ease-in-out group-hover:scale-110 text-center">
                    {resource.name}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniResources;
