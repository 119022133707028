import { useState } from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/Tabs";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "../components/ui/card";
import { Label } from "../components/ui/Label";
import { RadioGroup, RadioGroupItem } from "../components/ui/RadioGroup";
import { Slider } from "../components/ui/Slider";
import { Button } from "../components/ui/Button";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import Footer from "../components/layout/Footer";

const cities = {
  "New York, USA ": {
    housing: 2000,
    food: 500,
    transport: 150,
    entertainment: 300,
    vibe: 9,
  },
  "London, UK ": {
    housing: 1800,
    food: 450,
    transport: 170,
    entertainment: 250,
    vibe: 8,
  },
  "Tokyo, Japan ": {
    housing: 1500,
    food: 400,
    transport: 100,
    entertainment: 200,
    vibe: 9,
  },
  "Paris, France ": {
    housing: 1600,
    food: 420,
    transport: 80,
    entertainment: 220,
    vibe: 9,
  },
  "Berlin, Germany ": {
    housing: 1200,
    food: 350,
    transport: 90,
    entertainment: 180,
    vibe: 8,
  },
  "Sydney, Australia ": {
    housing: 1700,
    food: 430,
    transport: 110,
    entertainment: 240,
    vibe: 9,
  },
  "Seoul, South Korea ": {
    housing: 1100,
    food: 300,
    transport: 70,
    entertainment: 150,
    vibe: 8,
  },
  "Amsterdam, Netherlands ": {
    housing: 1400,
    food: 380,
    transport: 60,
    entertainment: 200,
    vibe: 8,
  },
};

export default function Component() {
  const [city1, setCity1] = useState<keyof typeof cities>("New York, USA ");
  const [city2, setCity2] = useState<keyof typeof cities>("London, UK ");
  const [housing, setHousing] = useState("shared");
  const [food, setFood] = useState("cook");
  const [transport, setTransport] = useState("public");
  const [entertainment, setEntertainment] = useState("movies");
  const [duration, setDuration] = useState(6);
  const [activeTab, setActiveTab] = useState("options");

  const calculateCost = (city: keyof typeof cities) => {
    const baseCosts = cities[city];
    let totalCost = 0;

    totalCost +=
      housing === "shared" ? baseCosts.housing * 0.7 : baseCosts.housing;
    totalCost += food === "cook" ? baseCosts.food * 0.8 : baseCosts.food * 1.2;
    totalCost +=
      transport === "public" ? baseCosts.transport : baseCosts.transport * 1.5;
    totalCost +=
      entertainment === "movies"
        ? baseCosts.entertainment * 0.9
        : baseCosts.entertainment * 1.1;

    return totalCost * duration;
  };

  const cost1 = calculateCost(city1);
  const cost2 = calculateCost(city2);

  const handleViewCostEstimate = () => {
    setActiveTab("results");
  };

  return (
    <div className="h-screen bg-Button">
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>
      <div className="flex justify-center items-center md:mt-16 bg-Button">
        <Card className="w-full md:max-w-6xl md:mb-10 bg-unimaytLight text-white">
          <CardHeader>
            <CardTitle className="md:text-4xl text-smheading font-heading text-center text-ButtonHover">
              🌍 Study Abroad Calculator
            </CardTitle>
            <CardDescription className="text-center text-Button">
              Compare living costs and vibes in different cities!
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs
              value={activeTab}
              onValueChange={setActiveTab}
              className="w-full"
            >
              <TabsList className="grid w-full grid-cols-2 gap-2">
                <TabsTrigger value="options">Options</TabsTrigger>
                <TabsTrigger value="results">Results</TabsTrigger>
              </TabsList>
              <TabsContent value="options">
                <div className="space-y-4 px-2">
                  <div className="grid grid-cols-2 md:gap-4 gap-2 mt-6">
                    <div>
                      <Label htmlFor="city1">Select City</Label>
                      <select
                        id="city1"
                        value={city1}
                        onChange={(e) =>
                          setCity1(e.target.value as keyof typeof cities)
                        }
                        className="w-full p-2 rounded bg-dark text-light"
                      >
                        {Object.keys(cities).map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <Label htmlFor="city2">Select City</Label>
                      <select
                        id="city2"
                        value={city2}
                        onChange={(e) =>
                          setCity2(e.target.value as keyof typeof cities)
                        }
                        className="w-full p-2 rounded bg-dark text-light"
                      >
                        {Object.keys(cities).map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <Label>Housing 🏠</Label>
                    <RadioGroup
                      defaultValue="shared"
                      onValueChange={setHousing}
                      className="flex space-x-4"
                    >
                      <div className="flex items-center space-x-1">
                        <RadioGroupItem value="shared" id="shared" />
                        <Label htmlFor="shared">Shared Room</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="non-shared" id="non-shared" />
                        <Label htmlFor="non-shared">Private Room</Label>
                      </div>
                    </RadioGroup>
                  </div>
                  <div>
                    <Label>Food 🍔</Label>
                    <RadioGroup
                      defaultValue="cook"
                      onValueChange={setFood}
                      className="flex space-x-4"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="cook" id="cook" />
                        <Label htmlFor="cook">Mostly Cook</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="eat-out" id="eat-out" />
                        <Label htmlFor="eat-out">Mostly Eat Out</Label>
                      </div>
                    </RadioGroup>
                  </div>
                  <div>
                    <Label>Transportation 🚌</Label>
                    <RadioGroup
                      defaultValue="public"
                      onValueChange={setTransport}
                      className="flex space-x-4"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="public" id="public" />
                        <Label htmlFor="public">Public Transport</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="rideshare" id="rideshare" />
                        <Label htmlFor="rideshare">Rideshare</Label>
                      </div>
                    </RadioGroup>
                  </div>
                  <div>
                    <Label>Entertainment 🎉</Label>
                    <RadioGroup
                      defaultValue="movies"
                      onValueChange={setEntertainment}
                      className="flex space-x-4"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="movies" id="movies" />
                        <Label htmlFor="movies">Movies & Chill</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="clubbing" id="clubbing" />
                        <Label htmlFor="clubbing">Clubbing</Label>
                      </div>
                    </RadioGroup>
                  </div>
                  <div>
                    <Label>Duration ({duration} months)⏳</Label>
                    <Slider
                      defaultValue={[6]}
                      max={12}
                      step={1}
                      onValueChange={(value) => setDuration(value[0])}
                      className="w-full pt-2"
                    />
                  </div>
                </div>
              </TabsContent>
              <p className="text-[19px] md:text-2xl md:pt-10 pt-6 font-bold text-ButtonHover">
                {Math.abs(cost1 - cost2) < 100
                  ? "The costs are very similar!"
                  : `The difference is $${Math.abs(cost1 - cost2).toLocaleString()} for ${duration} months.`}
              </p>

              <CardFooter className="flex justify-end">
                <Button
                  className="bg-Button hover:bg-ButtonHover md:-mt-16 mt-4 mb-6"
                  onClick={
                    activeTab === "options"
                      ? handleViewCostEstimate
                      : () => setActiveTab("options")
                  }
                >
                  {activeTab === "options" ? "View Cost Estimate" : "Back"}
                </Button>
              </CardFooter>

              <TabsContent value="results">
                <div className="grid grid-cols-2 gap-4">
                  <Card className="bg-dark">
                    <CardHeader>
                      <CardTitle className="md:text-2xl text-lg font-bold text-light">
                        {city1}
                      </CardTitle>
                      <CardDescription className="text-extralight">
                        Vibe Rating: {"🌟".repeat(cities[city1].vibe)}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <p className="text-2xl font-bold text-green-400">
                        ${cost1.toLocaleString()}
                      </p>
                      <p className="text-sm text-extralight">
                        Total for {duration} months
                      </p>
                      <div className="md:mt-8 mt-4 space-y-2">
                        <p>
                          Housing: $
                          {(
                            cities[city1].housing *
                            (housing === "shared" ? 0.7 : 1) *
                            duration
                          ).toLocaleString()}
                        </p>
                        <p>
                          Food: $
                          {(
                            cities[city1].food *
                            (food === "cook" ? 0.8 : 1.2) *
                            duration
                          ).toLocaleString()}
                        </p>
                        <p>
                          Transport: $
                          {(
                            cities[city1].transport *
                            (transport === "public" ? 1 : 1.5) *
                            duration
                          ).toLocaleString()}
                        </p>
                        <p>
                          Entertainment: $
                          {(
                            cities[city1].entertainment *
                            (entertainment === "movies" ? 0.9 : 1.1) *
                            duration
                          ).toLocaleString()}
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="bg-dark">
                    <CardHeader>
                      <CardTitle className="md:text-2xl text-lg font-bold text-light">
                        {city2}
                      </CardTitle>
                      <CardDescription className="text-extralight">
                        Vibe Rating: {"🌟".repeat(cities[city2].vibe)}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <p className="text-2xl font-bold text-green-400">
                        ${cost2.toLocaleString()}
                      </p>
                      <p className="text-sm text-extralight">
                        Total for {duration} months
                      </p>
                      <div className="md:mt-8 mt-4 space-y-2">
                        <p>
                          Housing: $
                          {(
                            cities[city2].housing *
                            (housing === "shared" ? 0.7 : 1) *
                            duration
                          ).toLocaleString()}
                        </p>
                        <p>
                          Food: $
                          {(
                            cities[city2].food *
                            (food === "cook" ? 0.8 : 1.2) *
                            duration
                          ).toLocaleString()}
                        </p>
                        <p>
                          Transport: $
                          {(
                            cities[city2].transport *
                            (transport === "public" ? 1 : 1.5) *
                            duration
                          ).toLocaleString()}
                        </p>
                        <p>
                          Entertainment: $
                          {(
                            cities[city2].entertainment *
                            (entertainment === "movies" ? 0.9 : 1.1) *
                            duration
                          ).toLocaleString()}
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="md:mt-10 mt-6 text-center mb-10">
                  <p className="md:text-2xl text-lg font-bold text-ButtonHover">
                    {city1} is{" "}
                    {Math.abs(cost1 - cost2) < 100
                      ? "about the same as"
                      : cost1 > cost2
                        ? "more expensive than"
                        : "cheaper than"}{" "}
                    {city2}
                  </p>
                </div>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>
      <div className="md:p-2 bg-Button">
        <Footer />
      </div>
    </div>
  );
}
