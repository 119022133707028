import React, { useCallback, useEffect } from "react";
import MyProfile from "../components/shared/profile/MyProfile";
import MainLayout from "../components/layout/MainLayout";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import UseUsers from "../services/useUsers";
import UseChat from "../services/useChat";
import { useLocalStorage } from "usehooks-ts";
import { Conversation } from "../types/ChatsInterface";
import { useUserStore } from "../zustandStore/useUserStore";

const Home: React.FC = () => {
  const { getUsers } = UseUsers();
  const { getChats } = UseChat();

  const setUsers = useUserStore((state) => state.setUsers);
  const [conversation, setConversation] = useLocalStorage<Conversation[]>(
    "conversation",
    []
  );

  const { data: usersData, isSuccess: usersSuccess } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const {
    status,
    data: chatsData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["chats"],
    queryFn: getChats,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.chats.length === 0) {
        return undefined;
      }
      return lastPage.current_page < lastPage.page_count
        ? lastPage.current_page + 1
        : undefined;
    },
  });

  const handleConversationUpdate = useCallback(
    (data: any) => {
      const allChats = data.flatMap((page: any) => page.chats);
      setConversation(allChats);
    },
    [setConversation]
  );

  useEffect(() => {
    if (status === "success" && chatsData?.pages) {
      handleConversationUpdate(chatsData.pages);
    }
  }, [chatsData, handleConversationUpdate, status]);

  useEffect(() => {
    if (usersSuccess && usersData?.data) {
      setUsers(usersData?.data);
    }
  }, [usersData, usersSuccess, setUsers]);

  return (
    <MainLayout
      status={status}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      conversation={conversation}
      setConversation={setConversation}
    >
      <MyProfile isLoading={!usersSuccess} />
    </MainLayout>
  );
};

export default Home;
